<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {useApp} from "@/shared/app.ts";
import {useRoute} from "vue-router";
import BlogSidebar from "@/apps/pages/comp/BlogSidebar.vue";

const app = useApp()
const route = useRoute()

const isLoading = ref<boolean>(true)

const post = computed(() => app.stores.posts.getBySlug(getSlug()))

const getSlug = (): string => {
  return route.params.slug as string
}

const getPost = async () => {
  try {
    const result = await app.services.posts.getBySlug(getSlug())
    if (result.success) {
      app.stores.posts.set(result.post)
    }
  } catch (ex) {
    app.logger.error(ex)
  }
}

onMounted(async () => {
  isLoading.value = true
  await getPost()
  await app.helpers.async.sleep(1000)
  isLoading.value = false
})
</script>

<template>
  <div v-if="isLoading" id="preloader"></div>
  <section v-if="post" class="top-position1 pt-0 pb-5">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>{{ post.title }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'blog'}">Our Blog</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="post" class="pt-0 blog-detail">
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-8 mb-2-9 mb-lg-0">
          <div class="posts-wrapper">
            <img :src="`${post.image.url}?width=1000`" alt="..." class="w-100">
            <div class="post-body wow fadeIn" data-wow-delay="200ms"
                 style="visibility: visible; animation-delay: 200ms; animation-name: fadeIn;">
              <div class="post-meta">
                <h2 class="h3">{{ post.title }}</h2>
                <ul class="meta-list">
                  <li><i class="fa fa-user pe-2 text-primary align-middle"></i>
                    {{ post.author.fullName }}
                  </li>
                  <li>
                    <i class="fa fa-calendar pe-2 text-primary align-middle"></i>
                    {{ post.updatedAt.format('MMM D, YYYY') }}
                  </li>
                  <li>
                    <span class="fa fa-tag pe-2 text-primary align-middle"></span>
                    {{ post.category.name }}
                  </li>
                </ul>
              </div>
              <div class="row mb-2-2 post-body-content">
                <div class="col-md-12" v-html="post.content.html"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <blog-sidebar/>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.post-body-content h1,
.post-body-content h2,
.post-body-content h3,
.post-body-content h4,
.post-body-content h5,
.post-body-content h6 {
  font-size: 18px !important;
}
</style>