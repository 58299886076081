import {Context} from "@/shared/context";
import {AsyncHelper} from "@/shared/helpers/async_helper";
import {DomHelper} from "@/shared/helpers/dom_helper.ts";
import {VeeValidateHelper} from "@/shared/helpers/vee_validate";

export class Helpers {
    public readonly dom: DomHelper
    public readonly async: AsyncHelper
    public readonly veeValidate: VeeValidateHelper

    constructor(ctx: Context) {
        this.dom = new DomHelper(ctx)
        this.async = new AsyncHelper(ctx)
        this.veeValidate = new VeeValidateHelper(ctx)
    }
}