<script setup lang="ts">
import {onMounted, ref} from "vue";
import ProjectsList from "@/apps/pages/comp/ProjectsList.vue";
import {useApp} from "@/shared/app.ts";

const app = useApp()

const isLoading = ref<boolean>(true)

const handleLoaded = async () => {
  await app.helpers.async.sleep(1000)
  isLoading.value = false
}
onMounted(() => {
  isLoading.value = true
})
</script>

<template>
  <div v-if="isLoading" id="preloader"></div>
  <section class="top-position1 pt-0 pb-5">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Our Recent Projects</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'projects'}">Projects</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-0">
    <div class="container">
      <div class="section-heading4">
        <span>Recent Projects</span>
        <h2 class="mb-2 w-75">Our recent and completed projects</h2>
        <div class="w-40 ms-auto me-auto">
          Here are some of our recent projects. We always provide a look at the difficulties for each project.
        </div>
      </div>
      <projects-list @load="handleLoaded"/>
    </div>
  </section>
  <router-view/>
</template>