<script setup lang="ts">

import {onMounted, toRef} from "vue";

const props = defineProps<{
  overlay?: boolean
  padding?: boolean
}>()

const overlay = toRef(props, 'overlay', false)
const padding = toRef(props, 'padding', false)

onMounted(() => {

})
</script>

<template>
  <template v-if="!overlay">
    <div class="spinner text-center d-flex aligns-items-center position-relative text-center spinner-background"
         :class="{padding:'padding'}">
      <div class="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="overlay position-absolute h-100 w-100">
      <div class="spinner text-center d-flex aligns-items-center position-relative text-center spinner-background">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </template>
</template>
<style scoped>
.spinner {
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: wait;
}

.spinner .loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: auto;
  top: calc(50% - 32px);
  left: calc(50% - 18px)
}

.spinner .loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 3px;
  border: 3px solid #0A61EE;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0A61EE transparent transparent transparent;
}

.spinner .loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner .loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner .loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  z-index: 99999;
  height: 100%;
  bottom: 0;
  background-color: background;
  border-radius: inherit;
}

.overlay {
  background-color: rgb(var(--bs-body-bg-rgb), 0.70) !important;
}

.spinner.padding {
  padding: 50px 0 50px;
}
</style>