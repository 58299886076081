import {type IServiceHttpResponse, Service} from "@/shared/service";
import {UserModel} from "@/shared/models/user_model";

export class UsersService extends Service {

    async create(body: any): Promise<IUserResult> {
        const endpoint = this.endpoint(`/users`)
        return this.privateApi.post(endpoint, body, true).then((response) => {
            const model = new UserModel(this.ctx, response.data['user'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                status: status,
                message: message,
                success: success,
                user: model,
            }
        })
    }

    public async getAll(params: IGetAllUsersParams): Promise<IUsersResult> {
        const endpoint = this.endpoint('/users', this.params(params))
        return this.privateApi.get(endpoint, true).then((response) => {
            const models: UserModel[] = []
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            const pagination = response.pagination
            if (response.data && Array.isArray(response.data['users'])) {
                response.data['users'].forEach((data: any) => {
                    models.push(new UserModel(this.ctx, data))
                })
            }
            return {
                status: status,
                message: message,
                users: models,
                success: success,
                pagination: pagination
            }
        })
    }

    public async getById(id: number): Promise<IUserResult> {
        const endpoint = this.endpoint(`/users/${id}`)
        return this.privateApi.get(endpoint, true).then((response) => {
            const model = new UserModel(this.ctx, response.data['user'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                user: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getByUsername(username: string): Promise<IUserResult> {
        const endpoint = this.endpoint(`/users/${username}`)
        return this.privateApi.get(endpoint, true).then((response) => {
            const model = new UserModel(this.ctx, response.data['user'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                user: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async updateById(id: number, body: any): Promise<IUserResult> {
        const endpoint = this.endpoint(`/users/${id}`)
        return this.privateApi.put(endpoint, body, true).then((response) => {
            const model = new UserModel(this.ctx, response.data['user'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                status: status,
                message: message,
                success: success,
                user: model,
            }
        })
    }

    public async deleteByID(id: number): Promise<IServiceHttpResponse> {
        const endpoint = this.endpoint(`/users/${id}`)
        return this.privateApi.delete(endpoint, true).then((response) => {
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                status: status,
                message: message,
                success: success
            }
        })
    }
}

interface IUserResult extends IServiceHttpResponse {
    user: UserModel
}

export interface IGetAllUsersParams {
    page: number
    limit?: number
}

interface IUsersResult extends IServiceHttpResponse {
    users: UserModel[]
}