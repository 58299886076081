import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "../views/HomeView.vue"
import AboutView from "../views/AboutView.vue"
import ContactView from "../views/ContactView.vue"
import LegalView from "../views/LegalView.vue"
import BookView from "@/apps/pages/views/BookView.vue";
import ServicesView from "@/apps/pages/views/ServicesView.vue";
import ServiceView from "@/apps/pages/views/ServiceView.vue";
import BlogView from "@/apps/pages/views/BlogView.vue";
import BlogArticleView from "@/apps/pages/views/BlogArticleView.vue";
import ProjectsView from "@/apps/pages/views/ProjectsView.vue";
import ProjectView from "@/apps/pages/views/ProjectView.vue";
import BlogCategoryView from "@/apps/pages/views/BlogCategoryView.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/book',
            name: 'book',
            component: BookView,
            meta: {
                title: 'Book'
            }
        },
        {
            path: '/about',
            name: 'about',
            component: AboutView,
            meta: {
                title: 'About Us'
            }
        },
        {
            path: '/contact',
            name: 'contact',
            component: ContactView,
            meta: {
                title: 'Contact Us'
            }
        },
        {
            path: '/legal/:slug',
            name: 'legal:details',
            component: LegalView,
            meta: {
                title: 'Legal'
            }
        },
        {
            path: '/services',
            name: 'services',
            component: ServicesView,
            meta: {
                title: 'Our Services'
            }
        },
        {
            path: '/services/:slug',
            name: 'services:details',
            component: ServiceView,
            meta: {
                title: 'Service'
            }
        },
        {
            path: '/projects',
            name: 'projects',
            component: ProjectsView,
            meta: {
                title: 'Client Projects'
            },
            children: [
                {
                    path: '/projects/:slug',
                    name: 'projects:details',
                    component: ProjectView,
                    meta: {
                        title: 'Project Details'
                    }
                },
            ]
        },
        {
            path: '/blog',
            name: 'blog',
            component: BlogView,
            meta: {
                title: 'Blog'
            }
        },
        {
            path: '/blog/:slug',
            name: 'blog:article',
            component: BlogArticleView,
            meta: {
                title: 'Blog Article'
            }
        },
        {
            path: '/blog/category/:slug',
            name: 'blog:category',
            component: BlogCategoryView,
            meta: {
                title: 'Blog Category'
            }
        },
    ]
})

export default router
