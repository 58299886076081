<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {useApp} from "@/shared/app";
import {Offcanvas} from "bootstrap";
import {useRoute, useRouter} from "vue-router";
import Spinner from "@/shared/comp/Spinner/Spinner.vue";

const app = useApp()
const route = useRoute()
const router = useRouter()

const slug = route.params.slug as string
const isShown = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const offcanvasElement = ref<HTMLElement | null>(null)

const project = computed(() => app.stores.projects.getBySlug(slug))

const getProject = async () => {
  try {
    const result = await app.services.projects.getBySlug(slug)
    if (result.success) {
      app.stores.projects.set(result.project)
    }
  } catch (ex) {

  }
}

onMounted(async () => {
  isLoading.value = project.value == null
  if (offcanvasElement.value) {
    const offcanvas = new Offcanvas(offcanvasElement.value)
    offcanvas.show()
    offcanvasElement.value.addEventListener('shown.bs.offcanvas', () => {
      isShown.value = true
    })
    offcanvasElement.value.addEventListener('hidden.bs.offcanvas', () => {
      isShown.value = false
      router.push({name: 'projects'})
    })
  }
  await getProject()
  await app.helpers.async.sleep(1000)
  isLoading.value = false
})
</script>

<template>
  <div ref="offcanvasElement" class="offcanvas offcanvas-end w-lg-50" tabindex="-1" aria-modal="true" role="dialog">
    <div class="offcanvas-header border-bottom">
      <h5 id="offcanvasRightLabel">{{ project?.title }}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div v-if="project" class="offcanvas-body position-relative">
      <spinner v-if="isLoading"/>
      <template v-if="!isLoading">
        <div class="p-1-6 p-sm-1-9 p-lg-2-2 mb-3 shadow-sm border rounded">
          <h3 class="h6 border-bottom border-color-extra-light-gray pb-3 mb-3">Project Info</h3>
          <p>{{ project.description }}</p>
          <ul class="project-info-list">
            <li><span class="project-label">Category</span>{{ project.category.name }}</li>
            <li><span class="project-label">Client</span>{{ project.clientName }}</li>
            <li><span class="project-label">Duration</span>{{ project.duration }}</li>
            <li class="project-rating"><span class="project-label">Rating</span>
              <ul class="rating">
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
                <li><i class="fa fa-star"></i></li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="row g-3">
          <div class="col-md-6">
            <div class="card mb-3 shadow-sm border rounded">
              <div class="card-header bg-light fw-semibold">
                Before Picture
              </div>
              <div class="card-body p-0">
                <img :src="`${app.config.backendServerBaseURL}/${project.beforeImage.path}?width=500`" alt=""
                     style="width: 100%;"/>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-3 shadow-sm border rounded">
              <div class="card-header bg-light fw-semibold">
                After Picture
              </div>
              <div class="card-body p-0">
                <img :src="`${app.config.backendServerBaseURL}/${project.afterImage.path}?width=500`" alt=""
                     style="width: 100%;"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div v-if="isShown" class="modal-backdrop fade show"></div>
</template>

<style scoped>
.offcanvas {
  z-index: 999999;
  width: 100%;
}

@media (min-width: 720px) {
  .offcanvas {
    width: 719px;
  }
}
</style>