<script setup lang="ts">
import {onMounted, ref} from "vue";
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {useApp} from "@/shared/app.ts";

const app = useApp()

const isLoading = ref<boolean>(true)

const handleEvents = () => {

}

const handleDateSelect = () => {

}

const handleEventClick = () => {

}

onMounted(async () => {
  isLoading.value = true
  await app.helpers.async.sleep(2000)
  isLoading.value = false
})


const calendarOptions = {
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin // needed for dateClick
  ],
  headerToolbar: {
    left: 'prev,next',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay'
  },
  initialView: 'dayGridMonth',
  initialEvents: [],
  editable: true,
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  weekends: true,
  select: handleDateSelect,
  eventClick: handleEventClick,
  eventsSet: handleEvents,
  selectConstraint: {
    start: new Date()
    //start: FullCalendar.moment().subtract(1, 'days'),
    //end: FullCalendar.moment().startOf('year').add(100, 'year')
  },
  /* you can update a remote database when these fire:
  eventAdd:
  eventChange:
  eventRemove:
  */
}
</script>

<template>
  <div v-if="isLoading" id="preloader"></div>
  <section class="top-position1 pt-0 pb-5">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Book Us</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'book'}">Book Us</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-0">
    <div class="container">
      <div class="card shadow-sm">
        <div class="card-body p-3">
          <full-calendar :options="calendarOptions"/>
        </div>
      </div>
    </div>
  </section>
  <section class="py-0">
    <div class="row g-0">
      <div class="col-xl-6 bg-img cover-background theme-overlay-blue-dark min-height-450 d-none d-xl-block"></div>
      <div class="col-xl-6">
        <div class="row g-0">
          <div class="col-sm-6 text-center">
            <div class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100 border-color-light-white border-sm-end border-bottom">
              <i class="ti-map-alt fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">135 Doe Run Fairburn, Atlanta, GA 30213</h6>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100 borders-bottom border-color-light-white">
              <i class="ti-mobile fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">727-210-9926</h6>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div
                class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100 borders-bottom border-lg-bottom-0 border-sm-end border-color-light-white">
              <i class="ti-email fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">info@boldabode.com</h6>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100">
              <i class="ti-time fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">Mon to Sat - 8 AM to 5 PM</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.fc-day-past .fc-daygrid-day-frame {
  cursor: default !important;
  background: rgba(238, 238, 238, 0.50) !important;
}

.fc-day-today .fc-daygrid-day-frame {
  cursor: pointer !important;
}

.fc-day-future .fc-daygrid-day-frame {
  cursor: pointer !important;
}
</style>