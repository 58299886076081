import {ref} from 'vue'
import {defineStore} from 'pinia'
import type {ProjectModel} from "@/shared/models/project_model";

export interface IProjectsStore {
    get(): ProjectModel[]

    set(value: ProjectModel | ProjectModel[]): void

    getById(id: number): ProjectModel | null

    getBySlug(slug: string): ProjectModel | null

    deleteById(id: number): void

    deleteAll(): void
}

export const useProjectsStore = defineStore('projects', (): IProjectsStore => {
    const data = ref<ProjectModel[]>([])

    const get = (): ProjectModel[] => {
        return data.value as ProjectModel[]
    }

    const set = (value: ProjectModel | ProjectModel[]): void => {
        if (Array.isArray(value)) {
            value.forEach((item) => {
                if (item.id > 0) {
                    const index = data.value.findIndex(value => value.id === item.id)
                    if (index < 0) {
                        data.value.push(item)
                    } else {
                        data.value[index] = item
                    }
                }
            })
        } else {
            const item = value
            if (item.id > 0) {
                const index = data.value.findIndex(value => value.id === item.id)
                if (index < 0) {
                    data.value.push(value)
                } else {
                    data.value[index] = value
                }
            }
        }
        data.value = data.value.sort((a, b) => b.id - a.id)
    }

    const getById = (id: number): ProjectModel | null => {
        const index = data.value.findIndex(value => value.id === id)
        if (index >= 0) {
            return data.value[index] as ProjectModel
        }
        return null
    }

    const getBySlug = (slug: string): ProjectModel | null => {
        const index = data.value.findIndex(value => value.slug === slug)
        if (index >= 0) {
            return data.value[index] as ProjectModel
        }
        return null
    }

    const deleteById = (id: number): void => {
        const index = data.value.findIndex(value => value.id === id)
        if (index >= 0) {
            data.value.splice(index, 1)
        }
    }

    const deleteAll = (): void => {
        data.value = []
    }

    return {set, get, getById, getBySlug, deleteById, deleteAll}
})