import {Context} from "@/shared/context";
import {ProjectsService} from "@/shared/services/projects_service";
import {MeService} from "@/shared/services/me_service";
import {AuthService} from "@/shared/services/auth_service";
import {PostsService} from "@/shared/services/posts_service.ts";
import {UsersService} from "@/shared/services/users_service.ts";

export class Services {
    public readonly me: MeService
    public readonly auth: AuthService
    public readonly users: UsersService
    public readonly posts: PostsService
    public readonly projects: ProjectsService

    constructor(ctx: Context) {
        this.me = new MeService(ctx)
        this.auth = new AuthService(ctx)
        this.users = new UsersService(ctx)
        this.posts = new PostsService(ctx)
        this.projects = new ProjectsService(ctx)
    }
}