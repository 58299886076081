import {EntityModel} from "@/shared/model";
import type {Context} from "@/shared/context";

export class FileModel extends EntityModel {
    public readonly url: string
    public readonly path: string

    constructor(ctx: Context, data: any) {
        super(ctx, data)
        this.url = this.getString('url')
        this.path = this.getString('path')
        if (this.url.length == 0 && this.path.length > 0) {
            this.url = `${ctx.config.backendServerBaseURL}/${this.path}`
        }
    }
}
