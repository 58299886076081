import {type IServiceHttpResponse, Service} from "@/shared/service";
import {UserModel} from "@/shared/models/user_model";

export class AuthService extends Service {

    public async login(body: object): Promise<ILoginResult> {
        const endpoint = this.endpoint('/auth/login')
        return this.privateApi.post(endpoint, body, true).then((response) => {
            return {
                status: response.status,
                message: response.message,
                success: response.code == 'success',
                user: new UserModel(this.ctx, response.data['user'])
            }
        })
    }

    public async logout(): Promise<boolean> {
        this.ctx.storage.authToken.delete()
        return this.ctx.storage.authToken.get() == null
    }

    public isAuthenticated(): boolean {
        return this.ctx.storage.authToken.exists()
    }
}

interface ILoginResult extends IServiceHttpResponse {
    user: UserModel
}