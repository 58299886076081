import {get} from 'lodash'
import {DateTime} from "@/shared/date_time";
import {Context} from "@/shared/context";

export class Model {
    protected ctx: Context
    protected data: object

    constructor(ctx: Context, data: object | null | undefined) {
        this.ctx = ctx
        this.data = data && typeof data === 'object' ? data : {}
    }

    protected get(key: string, def: any): any {
        const value = get(this.data, key)
        return value ? value : def
    }

    protected getString(key: string, def?: string): string {
        return `${this.get(key, def ? def : '')}`
    }

    protected getFloat(key: string, def?: number): number {
        return parseFloat(`${this.get(key, def ? def : 0)}`)
    }

    protected getNumber(key: string, def?: number): number {
        return parseInt(`${this.get(key, def ? def : 0)}`)
    }

    protected getObject(key: string, def?: any): any {
        return this.get(key, def ? def : {}) as any
    }

    protected getArray(key: string, def?: any[]): any[] {
        return this.get(key, def ? def : []) as Array<any>
    }

    protected getBoolean(key: string, def?: boolean): boolean {
        return this.get(key, def ? def : false)
    }

    protected getDateTime(key: string, def?: number): DateTime {
        return new DateTime(this.get(key, def))
    }

    protected getLatitude(key: string, def?: number): number {
        const value = this.getString(key)
        if (!value) {
            return def ? def : 0
        }
        const parts = value.split(',')
        if (parts.length == 0) {
            return def ? def : 0
        }
        return parseFloat(parts[0])
    }

    protected getLongitude(key: string, def?: number) {
        const value = this.getString(key)
        if (!value) {
            return def ? def : 0
        }
        const parts = value.split(',')
        if (parts.length < 2) {
            return def ? def : 0
        }
        return parseFloat(parts[1])
    }

    protected getMoneyStringFromCents(key: string, def?: number): string {
        return (parseInt(`${this.get(key, def ? def : 0)}`) / 100).toFixed(2)
    }

    protected getMoneyFloatFromCents(key: string, def?: number): number {
        return parseFloat(`${this.get(key, def ? def : 0)}`) / 100
    }

    protected isNull(key: string): boolean {
        const value = this.get(key, null)
        return !value

    }

    protected transformCloudflareImage(url: string, params: { width?: number, height?: number, fit?: string }): string {
        if (url.endsWith('/public')) {
            let paramsStr = '/'
            if (params.width && params.width > 0) {
                paramsStr = `${paramsStr}w=${params.width}`
            }
            if (params.height && params.height > 0) {
                if (paramsStr.length > 1) {
                    paramsStr = `${paramsStr},`
                }
                paramsStr = `${paramsStr}h=${params.height}`
            }
            if (paramsStr.length > 1) {
                if (params.width && params.height) {
                    paramsStr = `${paramsStr},fit=${params.fit ? params.fit : 'contain'}`
                }
                return url.replace('/public', paramsStr)
            }
        }
        return url
    }
}

export class EntityModel extends Model {
    public readonly id: number
    public readonly deletedAt: DateTime
    public readonly createdAt: DateTime
    public readonly updatedAt: DateTime

    constructor(ctx: Context, data: object | null | undefined) {
        super(ctx, data)
        this.id = this.getNumber('id', 0)
        this.deletedAt = this.getDateTime('deleted_at')
        this.createdAt = this.getDateTime('created_at')
        this.updatedAt = this.getDateTime('updated_at')
    }
}