import {type IServiceHttpResponse, Service} from "@/shared/service";
import {ProjectModel} from "@/shared/models/project_model";
import {ProjectCategoryModel} from "@/shared/models/project_category_model.ts";

export class ProjectsService extends Service {

    async create(body: any): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects`)
        return this.privateApi.post(endpoint, body, true).then((response) => {
            const model = new ProjectModel(this.ctx, response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                status: status,
                message: message,
                success: success,
                project: model,
            }
        })
    }

    public async getAll(params: IGetAllProjectsParams): Promise<IProjectsResult> {
        const endpoint = this.endpoint('/projects', this.params(params))
        return this.privateApi.get(endpoint).then((response) => {
            const models: ProjectModel[] = []
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            const pagination = response.pagination
            if (response.data && Array.isArray(response.data['projects'])) {
                response.data['projects'].forEach((data: any) => {
                    models.push(new ProjectModel(this.ctx, data))
                })
            }
            return {
                status: status,
                message: message,
                projects: models,
                success: success,
                pagination: pagination
            }
        })
    }

    public async getById(id: number): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects/${id}`)
        return this.privateApi.get(endpoint).then((response) => {
            const model = new ProjectModel(this.ctx, response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                project: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getBySlug(slug: string): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects/${slug}`)
        return this.privateApi.get(endpoint).then((response) => {
            const model = new ProjectModel(this.ctx, response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                project: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async updateById(id: number, body: any): Promise<IProjectResult> {
        const endpoint = this.endpoint(`/projects/${id}`)
        return this.privateApi.put(endpoint, body, true).then((response) => {
            const model = new ProjectModel(this.ctx, response.data['project'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                status: status,
                message: message,
                success: success,
                project: model,
            }
        })
    }

    public async deleteByID(id: number): Promise<IServiceHttpResponse> {
        const endpoint = this.endpoint(`/projects/${id}`)
        return this.privateApi.delete(endpoint, true).then((response) => {
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getAllCategories(params: { limit: number, page: number }): Promise<IProjectCategoriesResult> {
        const endpoint = this.endpoint('/projects/categories', this.params(params))
        return this.privateApi.get(endpoint).then((response) => {
            const models: ProjectCategoryModel[] = []
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            const pagination = response.pagination
            if (response.data && Array.isArray(response.data['categories'])) {
                response.data['categories'].forEach((data: any) => {
                    models.push(new ProjectCategoryModel(this.ctx, data))
                })
            }
            return {
                status: status,
                message: message,
                success: success,
                pagination: pagination,
                categories: models,
            }
        })
    }
}

interface IProjectResult extends IServiceHttpResponse {
    project: ProjectModel
}

export interface IGetAllProjectsParams {
    page: number
}

interface IProjectsResult extends IServiceHttpResponse {
    projects: ProjectModel[]
}

interface IProjectCategoriesResult extends IServiceHttpResponse {
    categories: ProjectCategoryModel[]
}