import type {Context} from "@/shared/context.ts";
import Typed from "typed.js";
import {CountUp} from "countup.js";

export class DomHelper {
    private ctx: Context

    constructor(ctx: Context) {
        this.ctx = ctx
    }

    public initTyped(element: HTMLElement | null) {
        if (element) {
            try {
                const data = JSON.parse(element.getAttribute('data-typed-strings') as string)
                new Typed(element, {
                    loop: true,
                    strings: data,
                    typeSpeed: 40,
                    backSpeed: 40,
                    backDelay: 1e3
                })
            } catch (ex) {
                this.ctx.logger.error(ex)
            }
        }
    }

    initCountUp(element: HTMLElement | null) {
        if (element) {
            const countUp = new CountUp(element, 989, {enableScrollSpy: true})
        }
    }

    public windowScrollTop(): void {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    public setDocumentTitle(prefix: string, suffix: any) {
        document.title = suffix ? `${prefix} - ${suffix}` : prefix
    }

    decodeHtmlEntities(encodedString: string): string {
        const parser = new DOMParser()
        const doc = parser.parseFromString(encodedString, 'text/html')
        const html = doc.documentElement.textContent
        if (!html) {
            return ''
        }
        return html
    }
}