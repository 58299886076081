<script setup lang="ts">
import {useApp} from "@/shared/app";
import {computed, onMounted, ref} from "vue";

const emit = defineEmits(['load'])

const app = useApp()

const page = ref<number>(1)
const projects = computed(() => app.stores.projects.get())

const getProjects = async () => {
  try {
    const result = await app.services.projects.getAll({page: page.value})
    if (result.success) {
      app.stores.projects.set(result.projects)
    }
  } catch (ex) {

  }
}

onMounted(async () => {
  await getProjects()
  emit('load')
})
</script>

<template>
  <div class="row portfolio-gallery d-flex align-items-stretch">
    <div v-for="project in projects" class="col-md-6 col-lg-4 mb-1-6 position-relative">
      <div class="shadow h-100">
        <router-link :to="{name:'projects:details',params:{slug:project.slug}}">
          <div class="card card-style39">
            <img :src="`${app.config.backendServerBaseURL}/${project.afterImage.path}?width=500`" alt=""
                 style="height:312px; object-fit: cover; object-position: center"/>
            <div class="card-body">
              <div class="w-100 h-100 bg-white">
                <img :src="`${app.config.backendServerBaseURL}/${project.beforeImage.path}?width=500`" alt=""
                     style="object-fit: cover;" class="w-100 h-100"/>
              </div>
              <!--
              <div class="w-100 h-100 bg-white">
                <div class="first d-flex align-items-center justify-content-center"
                     :style="`background-image:url(${app.config.backendServerBaseURL}/${project.beforeImage.path})`">
                  <span class="watermark">Before</span>
                </div>
                <div class="second d-flex align-items-center justify-content-center"
                     :style="`background-image:url(${app.config.backendServerBaseURL}/${project.afterImage.path})`">
                  <span class="watermark">After</span>
                </div>
              </div>
              -->
            </div>
          </div>
        </router-link>
        <div class="card-body p-4">
          <h4 class="h5 mb-3">
            <router-link :to="{name:'projects:details',params:{slug:project.slug}}">
              {{ project.title }}
            </router-link>
          </h4>
          <p class="w-90">
            {{ project.subtitle }}
          </p>
          <router-link :to="{name:'projects:details',params:{slug:project.slug}}" class="read-more">
            View Project Details
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.watermark {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.50);
  color: white;
  width: 80px;
  font-weight: 500;
  opacity: 0.8;
  font-size: 13px;
  text-transform: uppercase
}

.first .watermark {
  top: 0;
  left: 0;
  border-bottom-right-radius: 4px;
}

.second .watermark {
  bottom: 0;
  right: 0;
  border-top-left-radius: 4px;
}

.first,
.second {
  --s: 90px;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.first {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - var(--s)));
}

.second {
  margin-top: calc(var(--s) * -1 + 2px);
  clip-path: polygon(0 0, 100% var(--s), 100% 100%, 0 100%);
}
</style>