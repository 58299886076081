import {EntityModel} from "@/shared/model";
import {Context} from "@/shared/context";
import {PostCategoryModel} from "@/shared/models/post_category_model.ts";
import {FileModel} from "@/shared/models/file_model.ts";
import {UserModel} from "@/shared/models/user_model.ts";

export class PostModel extends EntityModel {
    public readonly slug: string
    public readonly title: string
    public readonly image: FileModel
    public readonly author: UserModel
    public readonly content: PostContent
    public readonly category: PostCategoryModel


    constructor(ctx: Context, data: any) {
        super(ctx, data)
        this.slug = this.getString('slug')
        this.title = this.getString('title')
        this.image = new FileModel(ctx, this.getObject('image'))
        this.author = new UserModel(ctx, this.getObject('author'))
        this.content = new PostContent(ctx, this.getString('content'))
        this.category = new PostCategoryModel(ctx, this.getObject('category'))
    }
}

class PostContent {
    public readonly html: string
    public readonly string: string

    constructor(ctx: Context, html: string) {
        this.html = ctx.helpers.dom.decodeHtmlEntities(html)
        this.string = this.html.replace(/<[^>]*>/g, '')
    }
}

