<script setup lang="ts">
import {useApp} from "@/shared/app.ts";
import {computed, onMounted, ref, toRef} from "vue";
import type {PostCategoryModel} from "@/shared/models/post_category_model.ts";

const emit = defineEmits(['loaded'])
const props = defineProps<{
  category?: PostCategoryModel | null
}>()

const app = useApp()

const page = ref<number>(1)

const category = toRef(props, 'category')
const posts = computed(() => app.stores.posts.get())

const getPosts = async () => {
  try {
    let params: any = {page: page.value}
    if (category.value) {
      params['category'] = category.value.id
    }
    const result = await app.services.posts.getAll(params)
    if (result.success) {
      app.stores.posts.set(result.posts)
    }
  } catch (ex) {
    app.logger.error(ex)
  }
}

onMounted(async () => {
  await getPosts()
  emit('loaded')
})
</script>

<template>
  <div v-for="post in posts" class="row g-0 mt-2-9 wow fadeIn" data-wow-delay="200ms"
       style="visibility: visible; animation-delay: 200ms; animation-name: fadeIn;">
    <router-link :to="{name:'blog:article', params:{slug:post.slug}}"
                 class="col-md-5 bg-img cover-background theme-overlay-blue-dark min-height-300 blog-image"
                 :style="`background-image: url(${post.image.url}?width=500)`">
    </router-link>
    <div class="col-md-7">
      <div class="card card-style7 h-100">
        <div class="card-body">
          <div class="p-2 bg-primary d-inline-block mb-3">
            <h6 class="text-white mb-0 display-31">
              {{ post.updatedAt.format('MMM D, YYYY') }}
            </h6>
          </div>
          <h5 class="mb-0">
            <router-link :to="{name:'blog:article', params:{slug:post.slug}}">
              {{ post.title }}
            </router-link>
          </h5>
          <ul class="blog-meta-list">
            <li>
              <span class="fa fa-user pe-2 text-primary align-middle"></span>
              {{ post.author.fullName }}
            </li>
            <li>
              <span class="fa fa-tag pe-2 text-primary align-middle"></span>
              {{ post.category.name }}
            </li>
          </ul>
          <p class="card-text" v-html="`${post.content.string.substring(0, 200)}...`"/>
        </div>
      </div>
    </div>
  </div>
</template>