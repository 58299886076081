<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue";
import {useApp} from "@/shared/app.ts";
import {useRoute} from "vue-router";
import BlogSidebar from "@/apps/pages/comp/BlogSidebar.vue";
import BlogList from "@/apps/pages/comp/BlogList.vue";

const app = useApp()
const route = useRoute()

const loader = ref<{ list?: boolean, sidebar?: boolean }>({})
const isLoading = ref<boolean>(true)

const category = computed(() => app.stores.postCategories.getBySlug(getSlug()))

const getSlug = (): string => {
  return route.params.slug as string
}

const getCategories = async () => {
  try {
    const result = await app.services.posts.getAllCategories({page: 1})
    if (result.success) {
      app.stores.postCategories.set(result.categories)
    }
  } catch (ex) {
    app.logger.error(ex)
  }
}

const handleBlogListLoaded = () => {
  loader.value.list = true
}

const handleBlogSidebarLoaded = () => {
  loader.value.sidebar = true
}


watch([loader, loader.value], async () => {
  if (loader.value.list && loader.value.sidebar) {
    await getCategories()
    await app.helpers.async.sleep(1000)
    isLoading.value = false
  }
})

onMounted(async () => {
  isLoading.value = true
})
</script>

<template>
  <div v-if="isLoading" id="preloader"></div>
  <section class="top-position1 pt-0 pb-5">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Our Blog</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'blog'}">Our Blog</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-0">
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-8 mt-n2-9 mb-2-9 mb-lg-0">
          <blog-list :category="category" @loaded="handleBlogListLoaded"/>
        </div>
        <div class="col-lg-4">
          <blog-sidebar @loaded="handleBlogSidebarLoaded"/>
        </div>
      </div>
    </div>
  </section>
</template>