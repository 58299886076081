<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useApp} from "@/shared/app.ts";

const app = useApp()

const isLoading = ref<boolean>(true)

onMounted(async () => {
  isLoading.value = true
  await app.helpers.async.sleep(2000)
  isLoading.value = false
})
</script>

<template>
  <div v-if="isLoading" id="preloader"></div>
  <section class="top-position1 pt-0 pb-5">
    <div class="page-title-section bg-img cover-background" data-overlay-dark="7">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb">
            <ul>
              <li>
                <router-link :to="{name:'home'}">Home</router-link>
              </li>
              <li>
                <router-link :to="{name:'contact'}">Contact Us</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="pt-0">
    <div class="container">
      <div class="section-heading4">
        <span>Get in touch</span>
        <h2>We’re always here to for you</h2>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-8 col-xl-7">
          <div class="p-1-6 p-sm-1-9 p-lg-2-2 box-shadow2">
            <div class="quform" action="" method="post" enctype="multipart/form-data" onclick="">

              <div class="quform-elements">

                <div class="row">

                  <!-- Begin Text input element -->
                  <div class="col-md-12">
                    <div class="quform-element form-group">
                      <label for="name">Your Name <span class="quform-required">*</span></label>
                      <div class="quform-input">
                        <input class="form-control" id="name" type="text" name="name" placeholder="Your name here">
                      </div>
                    </div>

                  </div>
                  <!-- End Text input element -->

                  <!-- Begin Text input element -->
                  <div class="col-md-6">
                    <div class="quform-element form-group">
                      <label for="email">Your Email <span class="quform-required">*</span></label>
                      <div class="quform-input">
                        <input class="form-control" id="email" type="text" name="email" placeholder="Your email here">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="quform-element form-group">
                      <label for="phone">Contact Number</label>
                      <div class="quform-input">
                        <input class="form-control" id="phone" type="text" name="phone" placeholder="Your phone here">
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12">
                    <div class="quform-element form-group quform-select-replaced">
                      <label for="subject">Your Subject <span class="quform-required">*</span></label>
                      <div class="quform-input">
                        <input class="form-control" id="subject" type="text" name="subject"
                               placeholder="Your subject here">
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12">
                    <div class="quform-element form-group">
                      <label for="message">Message <span class="quform-required">*</span></label>
                      <div class="quform-input">
                        <textarea class="form-control" id="message" name="message" rows="3"
                                  placeholder="Tell us a few words"></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- End Textarea element -->

                  <!-- Begin Captcha element -->
                  <div class="col-md-12">
                    <div class="quform-element">
                      <div class="form-group">

                      </div>
                    </div>
                  </div>
                  <!-- End Captcha element -->

                  <!-- Begin Submit button -->
                  <div class="col-md-12">
                    <div class="quform-submit-inner">
                      <button class="butn lg" type="submit"><span>Send Message</span></button>
                    </div>
                    <div class="quform-loading-wrap text-start"><span class="quform-loading"></span></div>
                  </div>
                  <!-- End Submit button -->

                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <section class="py-0">
    <div class="row g-0">
      <div class="col-xl-6 bg-img cover-background theme-overlay-blue-dark min-height-450 d-none d-xl-block"></div>
      <div class="col-xl-6">
        <div class="row g-0">
          <div class="col-sm-6 text-center">
            <div class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100 border-color-light-white border-sm-end border-bottom">
              <i class="ti-map-alt fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">135 Doe Run Fairburn, Atlanta, GA 30213</h6>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100 borders-bottom border-color-light-white">
              <i class="ti-mobile fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">727-210-9926</h6>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div
                class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100 borders-bottom border-lg-bottom-0 border-sm-end border-color-light-white">
              <i class="ti-email fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">info@boldabode.com</h6>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <div class="p-2-5 p-lg-6 p-xl-7 bg-primary h-100">
              <i class="ti-time fs-1 text-white"></i>
              <div class="borders-bottom border-width-2 opacity3 border-color-white mx-auto my-4 w-70px"></div>
              <h6 class="text-white font-weight-500">Mon to Sat - 8 AM to 5 PM</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <iframe
      class="w-100 border-top"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3324.7731103503966!2d-84.49780152462047!3d33.55927087334674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4e443ce4d3db9%3A0x530a80210a5fbdd2!2s135%20Doe%20Run%20Dr%2C%20Fairburn%2C%20GA%2030213%2C%20USA!5e0!3m2!1sen!2szw!4v1728811188966!5m2!1sen!2szw"
      height="450" style="border:0;" :allowfullscreen="true" loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"></iframe>
</template>