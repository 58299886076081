import {EntityModel} from "@/shared/model";
import {FileModel} from "@/shared/models/file_model";
import {Context} from "@/shared/context";
import {ProjectCategoryModel} from "@/shared/models/project_category_model.ts";

export class ProjectModel extends EntityModel {
    public readonly title: string
    public readonly subtitle: string
    public readonly slug: string
    public readonly duration: string
    public readonly category: ProjectCategoryModel
    public readonly description: string
    public readonly clientName: string
    public readonly beforeImage: FileModel
    public readonly afterImage: FileModel

    constructor(ctx: Context, data: any) {
        super(ctx, data)
        this.slug = this.getString('slug')
        this.title = this.getString('title')
        this.subtitle = this.getString('subtitle')
        this.duration = this.getString('duration')
        this.category = new ProjectCategoryModel(ctx, this.getObject('category'))
        this.clientName = this.getString('client_name')
        this.description = this.getString('description')
        this.beforeImage = new FileModel(ctx, this.getObject('before_image'))
        this.afterImage = new FileModel(ctx, this.getObject('after_image'))
    }
}