import {EntityModel} from "@/shared/model";
import {Context} from "@/shared/context";

export class PostCategoryModel extends EntityModel {
    public readonly slug: string
    public readonly name: string
    public readonly postCount: number
    public readonly description: string

    constructor(ctx: Context, data: any) {
        super(ctx, data)
        this.slug = this.getString('slug')
        this.name = this.getString('name')
        this.postCount = this.getNumber('post_count')
        this.description = this.getString('description')
    }
}