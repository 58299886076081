<script setup lang="ts">
import {computed, onMounted} from "vue";
import {useApp} from "@/shared/app.ts";

const emit = defineEmits(['loaded'])

const app = useApp()

const categories = computed(() => app.stores.postCategories.get())

const getCategories = async () => {
  try {
    const result = await app.services.posts.getAllCategories({page: 1})
    if (result.success) {
      app.stores.postCategories.set(result.categories)
    }
  } catch (ex) {
    app.logger.error(ex)
  }
}

onMounted(async () => {
  await getCategories()
  emit('loaded')
})
</script>

<template>
  <div class="blog-sidebar ps-lg-1-6 ps-xl-1-9">
    <div class="widget wow fadeIn sticky-lg-top" data-wow-delay="600ms"
         style="visibility: visible; animation-delay: 600ms; animation-name: fadeIn;">
      <h6 class="widget-title">Categories</h6>
      <ul v-for="category in categories" class="list-style9">
        <li v-if="category.postCount > 0">
          <router-link :to="{name:'blog:category',params:{slug:category.slug}}">
            {{ category.name }}
          </router-link>
          <span>{{ category.postCount }}</span>
        </li>
      </ul>
    </div>

  </div>
</template>

<style scoped>

</style>