import {type IServiceHttpResponse, Service} from "@/shared/service";
import {PostModel} from "@/shared/models/post_model";
import {PostCategoryModel} from "@/shared/models/post_category_model.ts";

export class PostsService extends Service {

    async create(body: any): Promise<IPostResult> {
        const endpoint = this.endpoint(`/posts`)
        return this.privateApi.post(endpoint, body, true).then((response) => {
            const model = new PostModel(this.ctx, response.data['post'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                status: status,
                message: message,
                success: success,
                post: model,
            }
        })
    }

    public async getAll(params: IGetAllPostsParams): Promise<IPostsResult> {
        const endpoint = this.endpoint('/posts', this.params(params))
        return this.privateApi.get(endpoint).then((response) => {
            const models: PostModel[] = []
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            const pagination = response.pagination
            if (response.data && Array.isArray(response.data['posts'])) {
                response.data['posts'].forEach((data: any) => {
                    models.push(new PostModel(this.ctx, data))
                })
            }
            return {
                status: status,
                message: message,
                posts: models,
                success: success,
                pagination: pagination
            }
        })
    }

    public async getById(id: number): Promise<IPostResult> {
        const endpoint = this.endpoint(`/posts/${id}`)
        return this.privateApi.get(endpoint).then((response) => {
            const model = new PostModel(this.ctx, response.data['post'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                post: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getBySlug(slug: string): Promise<IPostResult> {
        const endpoint = this.endpoint(`/posts/${slug}`)
        return this.privateApi.get(endpoint).then((response) => {
            const model = new PostModel(this.ctx, response.data['post'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                post: model,
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async updateById(id: number, body: any): Promise<IPostResult> {
        const endpoint = this.endpoint(`/posts/${id}`)
        return this.privateApi.put(endpoint, body, true).then((response) => {
            const model = new PostModel(this.ctx, response.data['post'])
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success' && model.id > 0
            return {
                status: status,
                message: message,
                success: success,
                post: model,
            }
        })
    }

    public async deleteByID(id: number): Promise<IServiceHttpResponse> {
        const endpoint = this.endpoint(`/posts/${id}`)
        return this.privateApi.delete(endpoint, true).then((response) => {
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            return {
                status: status,
                message: message,
                success: success
            }
        })
    }

    public async getAllCategories(params: { page: number, limit?: number }): Promise<IPostCategoriesResult> {
        const endpoint = this.endpoint('/posts/categories', this.params(params))
        return this.privateApi.get(endpoint).then((response) => {
            const models: PostCategoryModel[] = []
            const status = response.status
            const message = response.message
            const success = response.status == 200 && response.code === 'success'
            const pagination = response.pagination
            if (response.data && Array.isArray(response.data['categories'])) {
                response.data['categories'].forEach((data: any) => {
                    models.push(new PostCategoryModel(this.ctx, data))
                })
            }
            return {
                status: status,
                message: message,
                success: success,
                pagination: pagination,
                categories: models,
            }
        })
    }
}

interface IPostResult extends IServiceHttpResponse {
    post: PostModel
}

export interface IGetAllPostsParams {
    page: number
    category?: number
}

interface IPostsResult extends IServiceHttpResponse {
    posts: PostModel[]
}

interface IPostCategoriesResult extends IServiceHttpResponse {
    categories: PostCategoryModel[]
}